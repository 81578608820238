* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --ff-primary: "Larsseit";

  --bgclr-primary: #ffc630;
  --clr-primary: #f3f3f3;
  --clr-accent-400: #121418;
  --border-primary: #3a3e46;

  --fw-regular: 300;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;
}

body {
  margin: 0;
  font-family: "Larsseit";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:hover {
  color: #ffc630;
}

.register {
  min-height: 100vh;
  padding: 100px 0px;
  display: flex;
  align-items: center;
  width: 95%;
  max-width: 1100px;
  margin: 0 auto;
}

.left-reg {
  background-image: url("../src/assets/register-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border: solid 1px #3a3e46;
}

.reg-left-flex {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: 100%;
}

.reg-left-title,
.log-left-title {
  font-family: "Larsseit", sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
  color: #17171a;
  width: 85%;
}

.log-left-title {
  width: 60% !important;
}

.reg-left-content {
  font-family: "Larsseit", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.32px;
  text-align: center;
  color: rgba(23, 23, 26, 0.9);
  width: 85%;
}

.reg-container {
  /* height: 650px; */
  min-height: 650px;
}

.login-container {
  height: 580px;
  position: relative;
}

.left-reg {
  padding: 40px;
  /* height: 100%; */
  padding: 56px 40px 40px 40px;
}

.right-reg {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top: solid 1px #3a3e46;
  border-right: solid 1px #3a3e46;
  border-bottom: solid 1px #3a3e46;
  padding: 67px;
  height: 100%;
}

.login-logo-wrapper {
  padding-top: 30px;
}

.reg-gift {
  width: 400px;
}

.log-gift {
  width: 300px;
}

.reg-gift img {
  width: 100%;
  height: 100%;
}

.log-gift img {
  width: 100%;
  height: 100%;
}

.login-logo {
  width: 250px;
}

.register-card {
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #3a3e46;
  background-color: #17171a;
  margin-top: 20%;
}

.register .heading {
  font-family: "Larsseit", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.48px;
  color: #fff;
}

.reset-heading {
  font-family: "Larsseit", sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #fff;
  margin-bottom: 24px !important;
}

.reset-label {
  font-family: "Larsseit", sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 12px !important;
}

.reset-input {
  font-family: "Larsseit", sans-serif;
  font-weight: 300;
  border: none;
  outline: none;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: solid 1px #3a3e46;
  background-color: #24252a;
  color: #fff;
  margin-bottom: 24px;
}

.reset-input::placeholder {
  font-family: "Larsseit", sans-serif;
  font-weight: 300 !important;
}

.reset-eyeicon {
  position: absolute;
  right: 16px;
  top: 50px;
  cursor: pointer;
  color: #7d7e7f;
}

.reset-input::placeholder {
  font-family: "Larsseit", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #cececf;
}

.reset-btn {
  font-family: "Larsseit", sans-serif;
  font-weight: 500;
  padding: 16px 20px;
  border-radius: 56px;
  background-color: #ffc630;
  border: none;
  outline: none;
  width: 100%;
}

.head-log {
  font-family: "Larsseit", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #cececf;
  margin: 12px 0px 24px 0px;
}

.input-groups .input-label {
  margin: 10px 0px 6px 0px;
  font-family: "Larsseit", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  position: relative;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  /* opacity: 0.6; */
  filter: invert(1) brightness(2);
}

.input-groups .input-field {
  height: 50px;
  outline: none;
  border: none;
  position: relative;
  color: #fff;
  font-size: 13px;
  font-weight: normal;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid transparent; /* newly added*/
  background-color: #24252a;
}

.input-groups .input-field:hover {
  transition: border 0.5s ease-out;
  border: 1px solid #ffc630; /* newly added*/
}

.input-groups .reg_eye {
  position: absolute;
  top: 53px;
  height: 20px;
  width: 20px;
  right: 19px;
  cursor: pointer;
  color: #7d7e7f;
}

.seconds {
  position: absolute;
  right: 16px;
  top: 50px;
}

.input-groups.icons {
  position: relative;
}

.eyeicons {
  position: absolute;
  right: 16px;
  top: 50px; /*recently changed*/
  cursor: pointer;
}

.icons-num {
  position: absolute;
  right: 16px;
  top: 50px !important;
  cursor: pointer;
}

.qr {
  text-align: center;
}

.qr > img {
  box-shadow: 0 0 32px 0 rgb(151 134 47 / 27%);
  width: 140px;
  height: 140px;
  border-radius: 20px;
}

.qr-cont {
  margin: 15px 0;
  color: #cececf;
  font-weight: 300;
}

.nav-tabs {
  border-bottom: none;
  padding: 0;
  display: flex;
  gap: 25px;
  padding: 10px 0;
}

.nav-tabs > button {
  padding: 0;
}

.nav-tabs > button ::after {
  content: "";
  display: block;
}

.nav-tabs .nav-link {
  border: none;
  color: #888b93;
  font-family: "Larsseit", sans-serif;
  font-weight: 300;
  font-size: 16px;
}

.nav-tabs > button {
  background-color: transparent !important;
  border: 1px solid white;
}

.nav-link.active {
  color: #fff !important;
  padding-bottom: 7px;
  border-bottom: 3px solid #ffc630 !important;
}

.nav-link {
  color: #888b93 !important;
  border-bottom: 3px solid #d0d0d000 !important;
}

.terms {
  display: flex;
  gap: 10px;
  align-items: center;
}

.terms-new {
  margin: 17px 0px 24px 0px;
}

.terms label {
  margin: 0px;
  font-family: "Larsseit", sans-serif;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
}

.terms-check {
  font-family: "Larsseit", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.54;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}

.terms label span {
  color: #ffc630;
}

.terms a {
  color: #ffc630;
}

/* Hide the default checkbox */
.regular_checkbox {
  display: none;
}

/* Create a custom checkbox appearance */
.checkbox-container {
  position: relative;
  display: inline-block;
}

.checkbox-container label {
  display: inline-block;
  width: 15px;
  height: 15px;
  /* background-color: #ffc630; */
  border: 1px solid #ffc630;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  top: 3px;
}

/* tick styles */
.checkbox-container input:checked + label::after {
  content: "";
  position: absolute;
  top: 6px;
  left: 3px;
  width: 5px;
  height: 8px;
  border: solid black;
  border-width: 0 2.5px 2.5px 0;
  transform: rotate(45deg) translate(-50%, -50%);
  transform-origin: center;
}

.checkbox-container input:checked + label {
  background-color: #ffc630;
}

.terms-check {
  margin-top: 10px;
}

.Buy_sell {
  /* width: 462px; */
  /* height: 513px; */
  /* margin: 59px 50px 0 42px; */
  padding: 24px 24px;
  margin: 50px 0px;
  border-radius: 12px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(208, 208, 208, 0.5);
  /* background-color: #fff; */
}

.Submit button {
  width: 100%;
  border: none;
  outline: none;
  font-family: "Larsseit", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #17171a;
  padding: 16px;
  border-radius: 46px;
  background-color: #ffc630;
}

.foot p {
  height: 18px;
  font-family: "Larsseit", sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: normal;
  margin: 0px;
  margin-top: 24px !important;
  text-align: center;
  color: #cececf;
}

.foot p a {
  color: #ffc630 !important;
  text-decoration: none !important;
}

.register-left img {
  width: 250px;
  display: block;
  margin: 0px auto;
}

.register-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  height: 100%;
  top: 10%;
}

.register-left h4 {
  font-family: Bitter;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  margin: 40px 0px 12px 0px;
  text-align: center;
  color: #111827;
}

.register-left h5 {
  height: 56px;
  align-self: stretch;
  flex-grow: 0;
  font-family: "Larsseit", sans-serif;
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: rgba(17, 24, 39, 0.7);
}

.Verification {
  background-color: #17171a;
}

.Verification .cards {
  border-radius: 12px;
  padding: 80px 0px;
  border: solid 1px #3a3e46;
}

.Verification h6 {
  height: 27px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 0;
  margin: 40px 0px;
  font-family: "Larsseit", sans-serif;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.4px;
  color: #fff;
}

.Verification .heading {
  font-family: "Larsseit", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
  margin-bottom: 14px;
  letter-spacing: 0.4px;
  color: #fff;
}

.notify {
  width: 100%;
  font-family: "Larsseit", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: normal;
  color: #ffc630;
  margin: 16px 0px 24px 0px;
  padding: 12px;
  border-radius: 8px;
  background-color: rgba(255, 198, 48, 0.2);
}

.input-groups span.textgreen {
  font-family: "Larsseit", sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: normal;
  color: #ffc630;
}

header.scrolled .MuiAppBar-colorPrimary {
  /* transition: background-color 0.3s ease; */
  /* border-bottom: 3px solid #000 !important; */
  /* box-shadow: 0 4px 26px 0 rgba(0, 0, 0, 0.25) !important; */
  /* background-color: #1b8a3b !important; */
}

header.scrolled .MuiAppBar-colorPrimary {
  /* width: 1440px; */
  height: 80px;
  display: flex;
  flex-direction: row;
  transition: background-color 0.3s ease;
  justify-content: space-between;
  align-items: center;
  box-shadow: none !important;
  margin: 0;
  padding: 14px 20px;
  border-bottom: solid 1px rgba(58, 62, 70, 0.5);
  background-color: #17171a;
  background: transparent;
  backdrop-filter: blur(10px);
  position: fixed;
  /* z-index: 30; */
}

/*  */

.notify_flex {
  display: flex;
  gap: 10px;
}

.notify-asset {
  line-height: 21px;
}

.bulb {
  /* border: 1px solid #ffc630; */
  background-color: #bd9836;
  padding: 4px;
  border-radius: 50%;
  /* width: 50px; */
  height: 31px;
}

.bulb i {
  font-size: 23px;
  font-weight: 200;
  color: #fff;
  width: 100%;
  height: 100%;
}

.mar-top-bot {
  margin: 28px 0;
}

.add-key h2 {
  font-family: "Larsseit";
  font-size: 19px;
  font-weight: 400;
  color: #fff !important;
  margin-top: 31px;
  margin-bottom: 7px;
}

.add-key p {
  font-family: "Larsseit", sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #8e8e92 !important;
  margin-bottom: 10px;
  line-height: 22px;
}

/* scan-qr */

.scan-qr {
  background-color: #24252a;
  margin-top: 23px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 13px;
}

.scan-qr > img {
  /* box-shadow: 0 0 32px 0 rgb(151 134 47 / 27%); */
  width: 120px;
  height: 120px;
  background-color: #fff;
  border-radius: 12px;
  padding: 10px;
}

.scan-qr-cont {
  margin: 15px 0;
  color: #cececf;
  font-weight: 300;
}

.scan-qr-cont h5 {
  font-size: 20px;
  margin: 0px;
}
.scan-qr-cont h4 {
  margin: 0px;
}

.copy-key {
  border: 1px solid #cececf;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
}

.copy-content {
  margin-left: 10px;
}

.pad-0 {
  padding: 0px !important;
}

.logo-brand {
  width: 199px !important;
  margin-right: 20px;
}

.logo-brand img {
  width: 199x !important;
}

.more-wrapper {
  position: relative;
}

.more-select {
  background: transparent !important;
  border: none;
  outline: none;
  position: relative;
  font-family: "Larsseit";
  font-size: 16px;
  font-weight: 300;
  color: #fff !important;
}

.bell-notify {
  padding: 0px;
  position: relative;
}

.bell-indicator {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #ffc630;
  top: 7%;
  right: -3px;
  border-radius: 50%;
  border: 2px solid #17171a;
}

.bell-notify::after {
  content: "" !important;
  color: transparent !important;
  display: inline-block;
  margin-left: 0em;
  vertical-align: 0.255em;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  display: none;
}

.more-select:hover {
  background: transparent !important;
  color: #ffc630 !important;
}

.more-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notify-dropdown {
  width: 300px;
  max-height: 274px;
  overflow: scroll;
  padding: 0px 20px 26px 20px;
  border: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #1e2026;
  top: 76% !important;
  left: -190px !important;
}

.notify-contents {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notify-head-wrapper {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: #1e2026;
  padding-top: 26px;
  padding-bottom: 20px;
}

.asset-list-btn {
  padding: 6px 14px;
  border-radius: 36px;
  background-color: #ffc630;
  border: none;
  outline: none;
  font-family: "Larsseit", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #111827;
  /* padding: 8px 16px 6px 16px; */
}

.notify-contents h5 {
  font-size: 17px;
  font-family: "Larsseit";
  font-weight: 400;
  margin: 0px;
  color: #76777a;
}

.notify-contents h6 {
  font-size: 14px;
  font-family: "Larsseit";
  font-weight: 300;
  margin: 0px;
  color: #fff;
}

.time-notify {
  color: #cececf;
  font-size: 13px;
  font-weight: 300;
  font-family: "Larsseit";
}

.notify-contents button {
  border: none;
  outline: none;
  background-color: transparent;
  color: #ffc630;
  font-size: 12px;
  font-family: "Larsseit";
  font-weight: 300;
}

.notify-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
}

.nav-notify {
  margin: 0px;
  text-wrap: wrap;
}

.nav-notify-content {
  margin: 0px !important;
  display: flex;
  justify-content: space-between;
  gap: 17px;
  width: 100%;
}

.more-dropdown > p {
  font-family: "Larsseit", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #fff;
}

.arrow-left {
  margin-left: 3px;
}

.arrow-left {
  font-size: 10px;
}

.menu_statis .menu_new_typr {
  display: flex;
  align-items: center !important;
}

.menu_new_typr a {
  margin: 0px 16px;
  text-decoration: none;
  cursor: pointer;
  font-family: "Larsseit";
  font-size: 16px;
  font-weight: 300;
  color: #fff !important;
  text-wrap: nowrap;
}
/* .Market {
  width: 48px;
  height: 22px;
  margin: 0 28px;
   font-family: "Larsseit", sans-serif ;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111827;
} */

.menu_new_typr {
  display: flex;
  align-items: center !important;
}

.contact_button.nav-primary-icons.margin-lr {
  margin: 0px 26px;
}

.head-drops {
  font-family: "Larsseit", sans-serif;
  font-size: 16px;
  /* margin: 0px 16px; */
  color: #111827 !important;
  font-weight: normal;
  margin: 0px 0px 0px 23px;
}

.header-profile-wrap i {
  font-size: 25px;
  color: #89898a;
}

.head-btn-login {
  border: none;
  outline: none;
  margin-right: 12px;
  font-family: "Larsseit", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 10px;
  border-radius: 25px;
  color: #ffc630;
  background-color: transparent;
}

.head-btn {
  flex-grow: 0;
  padding: 8px 20px;
  border: none;
  outline: none;
  color: #17171a !important;
  margin-right: 12px;
  font-family: "Larsseit", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 25px;
  background-color: #ffc630;
}

.globe_btn {
  margin-left: 0px !important ;
}

.das {
  color: #2f3035;
  font-size: 25px;
  font-weight: 600;
}

.minimage {
  width: 40px;
}

.meus {
  color: #fff !important;
  width: 0.8em !important;
}

.menu_statis a {
  text-decoration: none;
  cursor: pointer;
  font-family: "Larsseit", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #fff !important;
  margin-bottom: 10px;
}

.drawa:nth-child(1) {
  margin-top: 15px;
}

.mobile_menu_icon {
  border-bottom: solid 1px rgba(58, 62, 70, 0.5);
  margin-top: 19px !important;
  padding-bottom: 15px;
}

.MuiDrawer-paperAnchorLeft {
  right: auto;
  background-color: #17171a !important;
  backdrop-filter: blur(10px);
  color: #fff !important;
}

.Buycrypto_title {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-top: 20px;
  /* border-bottom: 1px solid #e7e7e7; */
  padding-bottom: 20px;
}

.buy_head {
  /* border-bottom: 1px solid #e7e7e7; */
  margin-top: 25px;
}

.buy_head .nav-tabs {
  padding: 0px !important;
}

.buy_head .nav-link.active {
  padding: 5px 0px;
}

.Buy_sell .nav-link.active {
  font-family: Bitter;
  font-size: 24px;
  font-weight: 600;
  border: none !important;
}

.Buy_sell .nav-link {
  font-family: Bitter;
  font-size: 14px;
  font-weight: 600;
  border: none !important;
}

.Buy_sell .nav-tabs {
  margin-bottom: 12px;
}

.foot_frst {
  display: flex;
  gap: 12px;
  flex-direction: column;
  /* color: #fff; */
}
.bals_divs_insubhead {
  font-family: "Larsseit", sans-serif;
  font-size: 14px;
  letter-spacing: normal;
  color: #fff;
}
.foot_frst {
  width: 70%;
}

.buywith_cards button {
  height: 52px;
  align-self: stretch;
  flex-grow: 0;
  width: 100%;
  margin-top: 24px;
  border: none;
  outline: none;
  display: flex;
  font-family: "Larsseit", sans-serif;
  font-size: 16px;
  font-weight: 50s0;
  text-align: left;
  color: #111827;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  border-radius: 6px;
  background-color: #ffc630;
}

.buywith_cards button.sells {
  justify-content: center !important;
  background-color: #f45e71 !important;
}

.swap-footers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.swap-footers .swap-footL {
  font-family: "Larsseit", sans-serif;
  font-size: 14px;
  text-align: left;
  color: #111827;
}
.swap-footL h4 {
  flex-grow: 0;
  font-family: Bitter;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  color: #fff;
}
.swap-footers a {
  flex-grow: 0;
  font-family: "Larsseit", sans-serif;
  font-size: 14px;
  text-align: left;
  color: #ffc630 !important;
}
.swap-footers a i {
  font-size: 10px !important;
}

/* .deposit .form_div{
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 50px;
  align-items: stretch;
  gap: 12px;
} */

table > thead > tr > th {
  padding: 0px !important;
}

/* support styles */

.support-deposit {
  border: none !important;
}

.support-deposit .support-form-div {
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  border-left: 2px dashed rgba(255, 198, 48, 0.2);
  flex-direction: column;
  padding-left: 25px;
  justify-content: flex-start;
  padding-bottom: 50px;
  align-items: stretch;
  gap: 12px;
}

.support-deposit .support-form-div .sides {
  display: flex;
}

.support-deposit .support-form-div .support-category-span {
  position: absolute;
  height: 25px;
  left: -38px;
  width: 25px;
  background-color: #453a1e !important;
  border-radius: 50% !important;
  padding: 2px 9px;
  color: #ffc630;
  font-size: 14px;
}

/* deposit styles */

.deposit {
  padding: 24px 24px 0px 0px;
  border-radius: 12px;
  border: solid 0.5px #3a3e46;
  background-color: #17171a;
}

.deposit .form_div {
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  /* border-left: 2px dashed rgba(255, 198, 48, 0.2); */
  flex-direction: column;
  padding-left: 25px;
  justify-content: flex-start;
  padding-bottom: 35px;
  align-items: stretch;
  gap: 12px;
}

.form_div.boder-none {
  border: none !important;
}

.w-100 {
  width: 100%;
}

.deposit .form_div .sides {
  display: flex;
}

.Frame-14894 {
  width: 20px;
  height: 392px;
  flex-grow: 0;
}
.Ellipse-15 {
  width: 20px;
  height: 20px;
  flex-grow: 0;
  margin: 0 0 372px;
  padding: 0 8px 1px 7px;
  background-color: #33c2b9;
}
.Vector-19 {
  width: 1px;
  height: 373px;
  flex-grow: 0;
  margin: 19px 9.5px 12px;
  background-color: #33c2b9;
}

.qr-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.form_div .add_box {
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  border-radius: 4px;
  background-color: transparent;
  border: solid 1px #3a3e46;
  margin-top: -18px;
}

.form_div .rights {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
}

.form_div.mar-bot {
  margin-bottom: -38px;
}

.form_div .add_box .address {
  font-family: "Larsseit", sans-serif;
  font-weight: 300;
  font-size: 13px;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.deposit .form_div h4 {
  font-family: "Larsseit", sans-serif;
  font-size: 18px;
  margin: 0px;
  position: relative;
  font-weight: 500;
  letter-spacing: 0.36px;
  color: #fff;
}

.text-green {
  color: #22b477;
}

/* .deposit .form_div span {
  position: absolute;
  height: 25px;
  left: -38px;
  width: 25px;
  background-color: rgba(255, 198, 48, 0.2) !important;
  border-radius: 50% !important;
  padding: 2px 8px;
  color: #ffc630;
  font-size: 14px;
} */

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
}

.deposit .form_div h6 {
  font-family: "Larsseit", sans-serif;
  font-size: 14px;
  margin: 0px;
  letter-spacing: normal;
  color: #cececf;
}
.form_div p {
  font-family: "Larsseit", sans-serif;
  font-size: 13px;
  letter-spacing: normal;
  text-align: center;
  color: #cececf;
  font-weight: 200;
}
.dep-qr {
  width: 140px;
  height: 140px;
  border-radius: 20px;
  border: solid 1px rgba(208, 208, 208, 0.5);
  background-color: #fff;
  box-shadow: 0 0 50px 0 rgb(151 134 47 / 27%);
}

.dep-drops {
  height: 48px;
  color: #fff !important;
  display: flex !important;
  font-family: "Larsseit", sans-serif;
  font-weight: 300;
  border: solid 1px #3a3e46 !important;
  outline: none !important;
  font-size: 13px;
  align-items: center !important;
  padding: 14px 16px !important;
  border-radius: 12px !important;
  background: transparent;
}

.deposit-imp-notes {
  padding: 24px;
  border-radius: 12px;
  background-color: #24252a;
}

.imp-notes-title {
  display: flex;
  align-items: center;
  gap: 10px;
  display: flex;
  align-items: center;
}

.imp-notes-title p {
  font-family: "Larsseit", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28px;
  color: #cececf;
}

.deposit-imp-icon {
  width: 15px;
  height: 15px;
}

.imp-notes-content {
  /* border: 1px solid grey; */
  /* margin: 19px 0; */
  margin-top: 24px;
}

.imp-notes-content h6 {
  font-family: "Larsseit", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  color: #fff;
}

.imp-notes-content p {
  font-family: "Larsseit", sans-serif;
  font-size: 14px;
  font-weight: lighter;
  line-height: 1.43;
  letter-spacing: 0.14px;
  color: #cececf;
}

.swap-footers .swap-footL span {
  font-family: "Larsseit", sans-serif;
  font-size: 14px;
  text-align: left;
  color: #fff;
}
.swap_mid {
  margin: 12px !important;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  /* background: rgb(36 37 42) !important;
  color: #fff !important; */
  background-color: #ffc630 !important;
  color: #000 !important;
}

.ui.selection.dropdown {
  background: transparent !important;
  /* border: solid 1px #3a3e46 !important; */
}

.ui.selection.dropdown .menu > .item {
  border-top: 1px solid #3a3e46 !important;
}

.ui.dropdown .menu > .item {
  background: rgb(36 37 42) !important;
  color: #fff !important ;
}

.low-clr {
  color: #f84960;
  margin-left: 2px;
}

.medium-clr {
  color: #ffaa5f;
  margin-left: 2px;
}

.high-clr {
  color: #2ebd85;
  margin-left: 2px;
}

.verified_low_btn .dash-verified img {
  width: 21px;
  height: 21px;
}

.verified_low_btn .dash-notVerified img {
  width: 21px;
  height: 21px;
}

.dash-verified {
  border-radius: 60px;
  width: fit-content;
  color: #2ebd85;
  font-size: 14px;
  font-weight: 300;
  height: fit-content;
  font-family: "Larsseit", sans-serif;
}

.dash-notVerified {
  border-radius: 60px;
  width: fit-content;
  color: #f84960;
  font-size: 14px;
  font-weight: 300;
  height: fit-content;
  font-family: "Larsseit", sans-serif;
}

.identity-verify-succes {
  padding: 4px 8px;
  border-radius: 60px;
  width: -moz-fit-content;
  width: fit-content;
  color: #2ebd85 !important;
  background-color: #2ebd8529 !important;
  font-size: 14px;
  font-weight: 300;
  height: fit-content;
  font-family: "Larsseit", sans-serif;
}

.verified_low_btn .verify_success,
.secuirty_box_title .verify_success {
  /* padding: 4px 8px; */
  border-radius: 60px;
  width: -moz-fit-content;
  width: fit-content;
  color: #2ebd85;
  background-color: #2ebd8529;
  font-size: 14px;
  font-weight: 300;
  height: fit-content;
  font-family: "Larsseit", sans-serif;
}

.verified_low_btn .verify_success img {
  width: 21px;
  height: 21px;
}

.verified_low_btn .verify_fail img {
  width: 21px;
  height: 21px;
}

.verified_low_btn .verify_medium,
.secuirty_box_title .verify_medium {
  padding: 4px 8px;
  border-radius: 60px;
  background-color: #8a492b45;
  width: -moz-fit-content;
  width: fit-content;
  color: #ffaa5f;
  font-size: 12px;
  font-weight: 300;
  height: fit-content;
}

.verified_low_btn .verify_fail,
.secuirty_box_title .verify_fail {
  padding: 4px 8px;
  border-radius: 60px;
  width: -moz-fit-content;
  width: fit-content;
  color: #f84960;
  /* background-color: #2d1e23; */
  font-size: 14px;
  font-weight: 300;
  height: -moz-fit-content;
  height: fit-content;
  font-family: "Larsseit", sans-serif;
}

.no_records_text {
  font-family: "Larsseit", sans-serif;
  font-size: 12px;
  font-weight: 300;
  color: #cececf;
  margin-top: 12px;
}
.kyc_nation,
.css-13cymwt-control,
.css-t3ipsp-control {
  border: solid 1px #3a3e46 !important;
  background: #313237 !important;
  background-color: #313237 !important;
}
.css-1dimb5e-singleValue {
  color: #fff !important;
}
.kyc_address {
  min-height: 80px;
  width: 100%;
  border: solid 1px #3a3e46;
  background-color: #313237;
  outline: none;
  color: #fff;
  padding: 16px;
  border-radius: 4px;
}

.up_im_past {
  height: 110px;
  width: 110px;
}
.inner_frst_display {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.image_upload_kyc {
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.proofs_name {
  width: 100px;
  overflow-x: scroll;
  padding: 8px 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
}

.market_curr {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.foot_frst input {
  border: none;
  /* color: #cececf; */
  color: #fff;
  font-family: "Larsseit", sans-serif;
  font-size: 20px;
  /* font-weight: bold; */
  height: 27px;
  opacity: 0.9;
  outline: none;
  text-align: left;
  width: 100%;
  background-color: transparent;
}
.foot_frst input:focus-visible {
  outline: none;
}
.foot_frst {
  display: flex;
  gap: 2px;
  flex-direction: column;
  width: 70%;
}
.swap_lft_top {
  /* height: 98px; */
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  margin: 24px 0px 0px !important;
  flex-direction: row;
  /* justify-content: flex-start; */
  align-items: end;
  justify-content: space-between;
  gap: 10px;
  padding: 20px;
  border-radius: 12px;
  border: solid 1px #3a3e46;
  background-color: #313237;
  margin-top: 19px;
}

.swap_chng_frst .ui.inline.dropdown {
  border: none !important;
  outline: none !important;
  padding-bottom: 0px !important;
  display: flex;
  align-items: center;
  gap: 8px;
}
.swap_balance {
  font-family: "Larsseit", sans-serif;
  margin-left: 8px;
  color: #8b8b8c;
  font-size: 14px;
  font-weight: 300;
}

.swap_balance span {
  color: #111827;
}

.convert_card-summary {
  padding-top: 206px;
  /* padding-bottom: 40px; */
}
.swap_fst_rght {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 28px;
}
.swap_inner_main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  width: 100%;
}
.swap_change_clr {
  font-family: "Larsseit", sans-serif;
  margin-left: 8px;
  margin-top: 2px;
  color: #cececf;
  font-size: 14px;
  font-weight: 300;
}

.swap_change_num {
  font-family: "Larsseit", sans-serif;
  margin-left: 8px;
  margin-top: 2px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}

/* .swap_chng_frst .ui.dropdown .menu {
  left: 0px !important;
  top: 27px !important;
  align-self: flex-end !important;
} */

.swap_chng_frst .ui.dropdown .menu {
  left: -65px !important;
  top: 27px !important;
  align-self: flex-end !important;
  bottom: auto !important;
}

.swap_chng_frst .ui.inline.dropdown > .text {
  text-wrap: nowrap;
}

.swap_chng_frst {
  text-align: end;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.swap-chng-max {
  color: #ffc630;
  font-size: 12px;
  font-family: "Larsseit";
  font-weight: 300;
  margin-bottom: 13px;
}

.minimum button.notactive {
  padding: 10px 16px;
  border-radius: 36px;
  background-color: transparent;
  border: 1px solid #ffc630;
  /* cursor: not-allowed; */
  font-family: "Larsseit", sans-serif;
  font-size: 14px;
  font-weight: 500;
  width: 110px;
  color: #fff !important;
}

.minimum button.active {
  gap: 10px;
  padding: 10px 16px;
  border-radius: 36px;
  background-color: #ffc630;
  cursor: pointer;
  font-family: "Larsseit", sans-serif;
  font-size: 14px;
  font-weight: 500;
  width: 110px;
  /* border: 1px solid #ffc630; */
  color: #111827;
  border: none;
  outline: none;
}

.modal-days-active {
  padding: 4px 8px;
  border-radius: 22px;
  border: solid 1px #ffc630;
  font-family: "Larsseit", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #cececf;
  cursor: pointer;
}

.support_select {
  color: #000 !important;
}

.ref_new_main {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
}
.ref_new_social {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #33c2b9; */
}
.ref_view_main {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  /* border: 1px solid rgba(208, 208, 208, 0.5); */
  justify-content: end;
}
.invite_text {
  font-size: 16px;
  font-weight: 500;
  color: #ffc630;
}

.stake_new_hea {
  text-align: center;
  font-family: "Larsseit", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}

.stak_pop_new {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 0.5px solid #ffc630;
  padding: 12px 14px;
  border-radius: 10px;
}

.stake-pop-left {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 300;
}

.stake-pop-right {
  color: #fff;
  font-weight: 300;
}

.stak_pop_new_inn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* media query for register page */

@media (max-width: 1024px) {
  .primary-dep-btn {
    margin-right: 0px;
    margin-left: 25px;
  }
  .reg-left-title,
  .reg-left-content {
    width: 100%;
  }
  .log-left-title {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .register {
    padding: 70px 0px;
    /* padding: 120px 0px; */
    /* border: 8px solid black; */
  }
  .register-left {
    display: none;
  }
  .register-card {
    margin: 12% 0%;
  }
  .deposit {
    padding-left: 10px;
  }
}

@media (max-width: 960px) {
  header.scrolled .MuiAppBar-colorPrimary {
    padding: 14px 20px !important;
  }
  .das {
    display: none !important;
  }
}

/* tablet */
@media (max-width: 768px) {
  .left-reg {
    display: none;
  }
  .login-container {
    height: 520px;
  }
  .right-reg {
    border-radius: 16px;
    border: solid 1px #3a3e46;
    padding: 32px 28px;
    height: auto;
  }
  .Verification h6 {
    margin: 36px 0px 20px 0px;
  }
  .Verification .cards {
    border-radius: 12px;
    padding: 30px 0px;
    border: solid 1px rgba(208, 208, 208, 0.5);
  }

  .Buy_sell {
    margin: 20px 0px;
  }
  .img-fluid {
    width: 172px !important;
  }
  .mobile_menu_icon {
    margin-top: 15px !important;
  }
  .minimage {
    width: 25px;
  }
  /* .head-drops {
    margin: 0px 8px;
  } */
}

@media (max-width: 500px) {
  header.scrolled .MuiAppBar-colorPrimary {
    padding: 4px 10px !important;
  }

  .minimage {
    width: 20px;
  }
  .logo-brand img,
  .logo-brand {
    width: 172px !important;
  }
  .img-fluid {
    width: 140px !important;
  }

  header.scrolled .MuiAppBar-colorPrimary {
    /* width: 1440px; */
    height: auto !important;
  }

  .MuiToolbar-gutters {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .useres {
    padding: 0px !important;
  }
  .moons {
    width: 22px;
    margin: 0px 5px;
  }
  /* .head-drops {
    margin: 0px 8px;
  } */

  .header-profile-wrap i {
    font-size: 21px;
    color: #89898a;
  }
  .notify-dropdown {
    left: auto !important;
  }
  .head-drops {
    margin: 0px;
  }
  .form_div .add_box .address {
    width: 90%;
    overflow: scroll;
  }
}

/* mobile */
@media (max-width: 425px) {
  .Verification .cards {
    border-radius: 12px;
    padding: 30px 0px;
    border: solid 1px rgba(208, 208, 208, 0.5);
    margin: 0px 2px;
  }

  .mobile_menu_icon {
    margin-top: 9px !important;
    padding-bottom: 6px;
  }
  .MuiDrawer-paperAnchorLeft {
    width: auto;
  }
  /* .head-drops {
    margin: 0px 5px;
  } */
  .qr-wrapper {
    align-self: self-start;
  }
}

@media (max-width: 375px) {
  .esti-sub-container {
    gap: 20px;
  }
  .deposit_blc_content {
    flex-wrap: wrap;
    gap: 24px;
  }
  .header-profile-wrap i {
    font-size: 20px;
  }
  .logo-brand img,
  .logo-brand {
    width: 150px !important;
  }
}

@media (max-width: 320px) {
  .copy-key {
    border: 1px solid #cececf;
    padding: 5px 10px;
    border-radius: 50px;
    cursor: pointer;
  }
  .scan-qr {
    gap: 8px;
    padding: 10px;
  }

  .copy-content {
    margin-left: 10px;
  }
  .notify-dropdown {
    left: -10px !important;
  }
}

/* min-width */

@media (min-width: 1440px) {
  .register {
    background-position-x: 290px;
  }
  .register-card {
    width: 440px;
  }
}

.errorcss,
.text-red {
  padding: 5px;
  color: #f1493f;
}

.hide-checkbox {
  position: relative;
  display: inline-block;
}

.hide-checkbox label {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid #8b8b8c;
  background: transparent;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  top: 3px;
}

/* tick styles */
.hide-checkbox input:checked + label::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 2px;
  width: 5px;
  height: 8px;
  border: solid #ffc630;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg) translate(-50%, -50%);
  transform-origin: center;
}

.trade-dropdown {
  width: 329px;
  /* height: 152px; */
  padding: 26px; /* --previous padding*/
  /* padding: 20px 9px; */
  color: white;
  border: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #1e2026;
  text-wrap: nowrap;
  top: 76% !important;
  left: -8% !important;
}

.nav-trade-wrapper {
  text-wrap: wrap;
  display: flex;
  gap: 12px;
  cursor: pointer;
}

.nav-trade-wrapper img {
  margin-top: 3px !important;
  width: 18px;
  height: 18px;
}

.nav-trade-wrapper p {
  font-family: "Larsseit", sans-serif !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  line-height: 1.42 !important;
  color: rgba(206, 206, 207, 0.8) !important;
  /* text-wrap: wrap;
  width: 95%; */
}

.nav-trade-links {
  font-family: "Larsseit", sans-serif !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  letter-spacing: 0.28px !important;
  color: #fff !important;
  margin: 0px !important;
}

.nav-trade-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.trade-soon {
  padding: 3px 6px;
  border-radius: 40px;
  background-color: #ffc630;
  font-family: "Larsseit", sans-serif !important;
  font-size: 8px;
  font-weight: 500;
  color: #17171a;
}

.more-dropdown {
  width: 329px;
  /* height: 152px; */
  padding: 26px;
  color: white;
  border: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #1e2026;
  text-wrap: nowrap;
  top: 76% !important;
  left: -87% !important;
}

.nav-primary-icons:hover {
  /* filter: brightness(114%) saturate(100%) invert(62%) sepia(69%) saturate(605%) hue-rotate(360deg) brightness(104%) contrast(108%); */
  /* filter: brightness(1%) saturate(100%) invert(89%) sepia(8%) saturate(7237%)
    hue-rotate(332deg) brightness(107%) contrast(101%); */
  color: #ffc630 !important;
}

.header-profile-wrap i:hover {
  color: #ffc630 !important;
}

.custom-select-stake {
  position: relative;
  display: inline-block;
  width: 200px;
}

.nav-trade-links {
  position: relative;
}

.primary-nav-arrow {
  display: none;
  transform: translateX(10px);
  transition: transform 1s ease !important;
}

.nav-trade-links:hover .primary-nav-arrow {
  display: inline-block;
  transform: translateX(0);
  transition: transform 1s ease !important;
}

.email-popup-card {
  width: 414px;
  /* height: 358px; */
  padding: 26px 37px 46px 40px;
  border-radius: 12px;
  border: solid 1px #3a3e46;
  background-color: #17171a;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.email-popup-card.stake-claim-popup {
  padding: 18px 35px 30px 30px;
}

.email-pop-img {
  width: 154px;
  /* height: 154px; */
  margin: auto;
}

.email-pop-img img {
  width: 100%;
  height: 100%;
}

.email-popup-card h3 {
  font-family: "Larsseit", sans-serif !important;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}

.email-popup-card p {
  font-family: "Larsseit", sans-serif !important;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57;
  text-align: center;
  color: #cececf;
  width: 75%;
  margin: 10px auto 25px auto;
}

.email-pop-icon {
  font-size: 24px;
  color: #6b7280;
  text-align: end;
  cursor: pointer;
  margin-right: -19px;
}

.email-pop-btn {
  cursor: not-allowed !important;
}

@media (max-width: 425px) {
  .email-popup-card {
    padding: 26px 37px 33px 34px;
  }
  .email-popup-card h3 {
    font-size: 19px;
  }
}

@media (max-width: 375px) {
  .email-popup-card {
    width: 354px;
  }
}

@media (max-width: 320px) {
  .email-popup-card p {
    width: 100%;
  }
  .email-popup-card {
    width: 302px;
  }
}

.terms-container h5 {
  font-family: "Larsseit", sans-serif;
  /* font-size: 24px; */
  /* font-weight: 500; */
  /* line-height: 1.38; */
  /* color: #fff; */
  color: #ffc630;
  /* border: 1px solid grey; */
  padding-bottom: 4px;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
}

.terms-container h6 {
  font-family: "Larsseit", sans-serif;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #eaecef;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

.terms-container p {
  font-family: "Larsseit", sans-serif;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 300;
  color: #eaecef;
  font-size: 15px;
  width: 93%;
  text-align: justify;
  line-height: 1.8;
}
.terms-container h4 {
  padding-top: 12px;
  padding-bottom: 12px;
  color: #eaecef;
  font-size: 18px;
  line-height: 24px;
  white-space: pre-wrap;
}

.terms-container ul li {
  padding: 5px 0;
  font-family: "Larsseit", sans-serif;
  font-weight: 300;
  color: #eaecef;
  font-size: 15px;
  line-height: 1.8;
  width: 93%;
  text-align: justify;
}

/* .terms-container ol li {
  padding: 5px 0;
  font-family: "Larsseit", sans-serif;
  font-weight: 300;
  color: #eaecef;
  font-size: 15px;
  line-height: 1.8;
  width: 93%;
  text-align: justify;
} */

.terms-risk-warning {
  text-align: center;
}

.policy-sub-heading {
  padding-top: 12px;
  padding-bottom: 12px;
  color: #eaecef;
  font-size: 18px;
  line-height: 1.8;
  white-space: pre-wrap;
  text-align: justify;
}

.policy-bold {
  /* font-weight: 400; */
  color: #fff;
  line-height: 1.8;
}

li li {
  list-style-type: circle !important;
}

.terms-address {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.terms-email {
  font-family: "Larsseit", sans-serif;
  font-weight: 300;
  color: #eaecef;
  font-size: 15px;
  width: 93%;
  text-align: justify;
  line-height: 1.8;
}

.policy-terms-ad {
  padding-top: 17px !important;
}

@media (max-width: 1024px) {
  .policy-terms-ad {
    padding-top: 0px !important;
  }
}
